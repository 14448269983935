.custom-stepper {
    height: calc(100vh - 220px);
   .bs-stepper-header {
        background-color: #fff;
        border-top-left-radius: 0.5rem !important;
        border-bottom-left-radius: 0.5rem !important;
        flex-wrap: nowrap !important;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #d0d0d0;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #d5d5d5;
        }

        .step {
            position: relative;

            &:first-child {
                .step-trigger {
                    margin-top: 0px !important;
                }
            }

            &.crossed, &.active, &:hover {
                .bs-stepper-title, .bs-stepper-box {
                    color: #449fd8 !important;
                }
            }

            &.active, &:hover {
                .step-trigger {
                    position: relative;
                    ::after {
                        content: '';
                        width: 40px;
                        height: 3px;
                        top: 25px;
                        right: -40px;
                        -webkit-transform: rotate(90deg) translate3d(0,225%,0);
                        transform: rotate(90deg) translate3d(0,225%,0);
                        background: linear-gradient(30deg,#449fd8,rgba(68,159,216,.5))!important;
                        position: absolute;
                        transition: -webkit-transform .3s;
                    }
                }
            }
        }

       .step-trigger {
           flex-direction: column !important;
            justify-content: center;
            width: 100%;
            padding: 0px !important;
            margin-top: 1rem;

            .bs-stepper-label {
                opacity: 0;
                font-size: 10px;
                margin-left: 0 !important;
            }
        }

        .bs-stepper-box {
            background-color: transparent !important;
            box-shadow: none !important;
        }
        
        &:hover {
            .step-trigger {
                .bs-stepper-label {
                    opacity: 1 !important;
                }
            }
        }

   }

   .bs-stepper-content {
        width: fit-content !important;
        padding-top: 1.5rem !important;
        box-shadow: none !important;
        border-top-right-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #d0d0d0;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #d5d5d5;
        }

        #deployment-links, #basic-details, #advance-info{
            max-width: 300px !important;
        }
   }


}