.skeleton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    // background-color: #f2f2f2;
    padding: 15px;
    border-radius: 10px;
    div {
        background-color: #f3f3f3;
        border-radius: 5px;
    }

    .skeleton-logo {
      height: 36px;
      width: 36px;
      border-radius: 100%;
    }

    .skeleton-head {
      height: 10px;
      width: 150px;
      margin-bottom: 10px;
    }

    .skeleton-text {
      height: 10px;
      margin-bottom:10px ;
    }

    .skeleton-time {
      height: 8px;
      width: 90px;
    }

    .custom-button {
      height: 36px;
      width: 76px;
    }

    .skeleton-button {
      height: 36px;
      width: 76px;

      &:last-child {
        width: 35px;
      }
    }

  }
  
  /* Animation */
  @keyframes fill-skeleton {
    0% {
      background-color: #f3f3f3;
    }
    50% {
      background-color: #ecebeb;
    }
    100% {
      background-color: #f3f3f3;
    }
  }
  
  .skeleton div {
    animation-name: fill-skeleton;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  

@media screen and (max-width: 768px) {
    .skeleton {
        section {
            width: 100%;
            &:first-child {
                text-align: start;
                margin-top: 12px;
            }
            &:last-child {
                justify-content: flex-end;
            }
        }
    }
}