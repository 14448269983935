.danger-badge {
  color: #f93262;
  background: rgba(249, 50, 98, 0.1) !important;
  font-size: 0.75rem !important;
  border-radius: 25px;
}
.success-badge {
  color: rgb(50, 249, 80);
  background: rgba(249, 50, 98, 0.1) !important;
  font-size: 0.75rem !important;
  border-radius: 25px;
}

.web-component-hover {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: 0.5s;
    cursor: pointer;
  }

  .icon {
    padding: 10px !important;
    border-radius: 15px;
  }

  .title {
    font-weight: 600;
    font-size: 1.3rem;

    .badge {
      margin: 0 0 2px 5px !important;
    }
  }
}

.logo-custom {
  width: 36px;
  height: 36px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 10px;
  // color: #fff;
  // background-color: var(--primary);
  // border-radius: 24px;
  // height: fit-content;
}

.custom-input {
  label {
    margin-bottom: 0px;
    padding-left: 10px;
    span {
      font-size: 0.85rem;
      padding: 3px 0px;
    }
    span:last-child {
      padding-left: 5px;
    }
  }
}

.custom-button {
  margin-top: 10px;
  width: 100%;
  button {
    font-size: 0.85rem;
    padding: 0.5rem;
    width: 50%;
  }
  button.active {
    background-color: rgba(115, 103, 240, 0.7);
    color: #000000;
  }
}

.mobile-index {
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  height: 82vh !important;
}

.heading-alert {
  line-height: 2;
}
.tabs-common {
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji",
    sans-serif !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
}

.hoc-time-div {
  .timeDiv {
    margin-top: 14px !important;
  }
  .selected_time > p {
    margin-bottom: 0;
  }
  .selected_time {
    margin-bottom: 16px;
  }
}
