.pricing-modal {
  .item-tabs > a {
    position: relative;
    width: 200px;
    z-index: 2;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    border-radius: 12px 12px 0 0;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 50%;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background: #fff;
  }
  .item-tabs > .active {
    border: 1px solid #e7e7e7;
    border-bottom: 0;
    background: #f8f8f8;
  }
  .nav-tabs {
    gap: 0.4rem !important;
    margin-bottom: 0;
    border-bottom: 1px solid #f8f8f8;
  }
  .nav-tabs .nav-link:after {
    background: transparent !important;
  }
  .CloseIcon {
    display: flex;
    justify-content: flex-end;
    padding: 9px;
    cursor: pointer;
  }
  .pricingCardBody {
    background-color: #f8f8f8;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 30px;
    line-height: 1;
    opacity: 0.75;
    color: #c8c4c4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-next {
    right: -21px;
  }
  .slick-prev {
    left: -31px;
  }
}
