// Inline code
code {
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: $code-color;
  background-color: $kbd-bg;
  @include border-radius($border-radius/2);
}
pre {
  // background-color: #f7f7f9;
  background: #eee;
  // margin: 1rem;
  // overflow: auto;
  border-radius: 3px;
  padding: 1rem;
  // max-width: 80ch;
  code {
    // background-color: transparent !important;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
  }
}
