.calendar-custom {
    position: fixed;
    top: 66px;
    width: 100%;
    .col-md-3 {
        width: 390px;
    }
    .card {
        margin-bottom: 0px !important;
    }
    .nav-vertical {
        min-width: 65px;
        height: 100vh !important;
        width: 100%;
        background: white;
        overflow: visible;

        .nav.nav-tabs.nav-left {
            font-size: 12px;
            margin: 0px 0px;
            margin-right: 0;
        }

        .nav-left:hover {
            span {
                opacity: 1;
            }
        }

        .nav-item {
            svg {
                margin: 0px 5px 0px 5px !important;
            }
            span {
                opacity: 0;
                font-size: 10px;
                transition: 0.5s;
            }

            .nav-link {  
                padding: 2px 5px 0px 5px;
                width: 60px;
                height: 60px;
                background-color: transparent;
                -webkit-transition: background-color 0.2s ease;
                transition: background-color 0.2s ease;
                padding: 4px 2px 8px;
                &:hover{
                    background-color: #ececec;
                }
            }

        }

        .help-button {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        .nav-link{
            &:hover .appearance-icon{
                fill: $primary;
            }
        }
        .color-picker-circle{
            min-width: 24px;
            height: 24px;
            border-radius: 12px;
            border: 2px solid #aca9a9;

            &:hover {
                transform: scale(1.1);
                transition: 0.2s;
            }
        }

        .btn-padding {
            padding: 8px 14px;
        }
        .img-preview {
            margin-top: 10px;
            padding: 10px;
            overflow: hidden;
            background-position: center center;
            background-repeat: repeat;
            background-size: 24px 24px;
            background-image: linear-gradient(to bottom, rgb(0 0 0 / 52%), rgb(0 0 0 / 36%)), url('../../../../assets/images/webcomponent/layout/bg.png');
        }

        .heading-size {
            #heading-size-select {
                padding: 0;
            }
            svg {
                display: none;
            }

        }

    }

    .tab-content {
        min-width: 160px;
        font-size: 12px;

        .sidebar_toggle_button_set {
            position: relative;
            left: 40px;
            top: 315px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8f9fa;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-left: none !important;
        }
        .sidebar_toggle_button_setContainer {
            position: relative;
            left: 21px;
            top: 276px;
            width: 28px;
            cursor: pointer;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8f9fa;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-left: none !important;
        }
        .full_width_icon_set {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            left: 25px;
        }
    
        .tab-pane {
            padding: 0 10px;
        }
        .tab-pane.active {
            height: 94vh;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 5px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #d0d0d0;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #d5d5d5;
            }
        }
    }

    .copy-icon-btn {
        padding: 8px;
        margin-bottom: 8px;
    }

    .switch-input {
        margin-left: -2.5rem !important;
    }

    .input-day {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        color: $primary;
        caret-color: #ffffff;
        text-align: right;
        max-width: 40px;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        -webkit-appearance: none;
    }

    .layout-grid {
        .item {
            text-align: center;
            padding: 5px 10px;
            border-radius: 5px;
            border: 1px solid transparent;
            &:hover {
                cursor: pointer;
                background-color: #eeeeee;
            }
        }
        .active {
            border: 1px solid $primary !important;
            color: $primary !important;
            &:hover {
                background-color: transparent;
            }
            
            svg {
                fill: $primary !important;
            }
        }
    }
}

.overlflow-y-auto {
    overflow-y: auto;
    height: 94vh;
    overflow-x: hidden;
}

.editor-custom {
    .col-md-3 {
        width: 390px;
    }
    .card {
        margin-bottom: 0px !important;
    }
    .nav-vertical {
        height: 94vh !important;
        // position: fixed;
        // width: 370px;
        background: white;

        .nav.nav-tabs.nav-left {
            font-size: 12px;
            margin-right: 0;
        }

        .nav-left:hover {
            span {
                opacity: 1;
            }
        }

        .nav-item {
            padding: 0.25em !important;
            svg {
                margin: 0 0 5px 0px !important;
            }
            span {
                opacity: 0;
                font-size: 10px;
                transition: 0.5s;
            }

            .nav-link {  
                padding: 10px 5px 0px 5px;
            }

        }

        .help-button {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        .nav-link{
            &:hover .appearance-icon{
                fill: $primary;
            }
        }
        .color-picker-circle{
            min-width: 24px;
            height: 24px;
            border-radius: 12px;
            border: 2px solid #aca9a9;

            &:hover {
                transform: scale(1.1);
                transition: 0.2s;
            }
        }

        .btn-padding {
            padding: 8px 14px;
        }
        .img-preview {
            margin-top: 10px;
            padding: 10px;
            overflow: hidden;
            background-position: center center;
            background-repeat: repeat;
            background-size: 24px 24px;
            background-image: linear-gradient(to bottom, rgb(0 0 0 / 52%), rgb(0 0 0 / 36%)), url('../../../../assets/images/webcomponent/layout/bg.png');
        }

        .heading-size {
            #heading-size-select {
                padding: 0;
            }
            svg {
                display: none;
            }

        }

    }

    .tab-content {
        font-size: 12px;
        .tab-pane {
            padding: 0 10px;
        }
        .tab-pane.active {
            height: 94vh;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 5px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #d0d0d0;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #d5d5d5;
            }
        }
    }

    .copy-icon-btn {
        padding: 8px 12px;
        margin-bottom: 8px;
    }

    .switch-input {
        margin-left: -2.5rem !important;
    }

    .input-day {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        color: $primary;
        caret-color: #ffffff;
        text-align: right;
        max-width: 40px;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        -webkit-appearance: none;
    }

    .layout-grid {
        .item {
            text-align: center;
            padding: 5px 10px;
            border-radius: 5px;
            border: 1px solid transparent;
            &:hover {
                cursor: pointer;
                background-color: #eeeeee;
            }
        }
        .active {
            border: 1px solid $primary !important;
            color: $primary !important;
            &:hover {
                background-color: transparent;
            }
            
            svg {
                fill: $primary !important;
            }
        }
    }
}

.MuiPopover-paper {
    max-height: 300px !important;
    &::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #d0d0d0;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #d5d5d5;
    }

    .MuiMenu-list {
        padding: 0px;
        background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
        margin: 0px;
    
        
        li {
            padding: 6px 24px;
            background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
            
            &[aria-selected="true"] {
                background-color: $primary !important;
                color: #fff;
            }
        }
    }
}

.custom-dialog {
    position: absolute;
    width: 100%;
    z-index: 111;
    margin-top: -150px;

    .switch-input {
        right: 0;
    }
}

.dialog-backdrop {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    -webkit-backdrop-filter: grayscale(100%);
    backdrop-filter: grayscale(100%);
    pointer-events: all;
}

.btn-padding {
    padding: 5px 10px;
}


@media screen and (max-width: 768px) {
    .calendar-custom {
        flex-direction: column;
        top: 102px;
        .nav-vertical {
            position: relative;
            height: 0px !important;
            z-index: 1;
            .nav-left {
                display: flex !important;
                height: 60px !important;
                position: fixed;
                bottom: 0;
                width: 100%;
                justify-content: center;
                z-index: 11;
                background: #fff;
                margin: 0;

                .nav-item {
                    padding-top: 0px !important;

                    span {
                        opacity: 1 !important;
                    }

                    svg {
                        width: 18px !important;
                        height: 18px !important;
                    }

                    .nav-link::after {
                        -webkit-transform: rotate(0deg) translate3d(0, 225%, 0) !important;
                        transform: rotate(0deg) translate3d(0, 225%, 0) !important;
                    }

                    &:hover::after, .active::after {
                        left: auto;
                        top: -7px !important;
                        width: 100% !important;
                        right: auto !important;
                        -webkit-transform: rotate(0deg) translate3d(0, 225%, 0) !important;
                        transform: rotate(0deg) translate3d(0, 225%, 0) !important;
                    
                    }
                }

                .help-button {
                    position: relative;
                    width: auto;
                }
            }

            .tab-content {
                width: 100%;
                // position: fixed;
                background: #fff;
                z-index: 11111;
                height: calc(100vh - 81px);
                display: none;

                .tab-pane {
                    height: 100%;
                    overflow-x: hidden;
                }
            }
        }

        &.mobile-sidebar {
            .nav-vertical {
                height: auto !important;

                .tab-content {
                    display: block !important;
                }
            }
        }

    }
}

@media screen and (max-width: 582px) {
    .calendar-custom {
        .nav-left {            
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            // padding-left: calc(10rem -40vw);
        }
    }
}