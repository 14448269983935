.disable-btn {
  cursor: no-drop !important;
  color: #449fd8 !important;
  opacity: 0.65 !important;
}
.h-fit {
  height: fit-content;
}
.w-fit {
  width: fit-content;
}

.proxy__detail {
  label {
    margin-top: 1rem;
  }
}

.section-listing:last-child {
  border-bottom: none !important;
}

.web-comp-heading {
  font-size: 14px;
  font-weight: 500;
}
.web-date-comp-heading {
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #5e5873;
}

.section-container::-webkit-scrollbar {
  display: none;
}

.f5bi43k .f4m2ahc > tbody > tr > th {
  padding-left: 0px
}
.f4m2ahc > div > button {
  width: 47px;
}