// ================================================================================================
//     File Name: subscription.scss
//     Description: Tables pages custom scss
//     Main component: Pipeline.js
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.heading-pill {
  position: relative;
  bottom: 7px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 8px;
  color: rgba(17, 17, 17, 0.5);
  background: transparent;
  box-shadow: 0px 0px 0px 1px rgb(17 17 17 / 30%) inset;
}

.p-date {
  font-size: 9px;
  font-weight: 400;
  text-align: end;
  color: rgba(17, 17, 17, 0.5);
  text-transform: uppercase;
}

.limit-progress {
  width: 250px;
  padding: 10px 10px 0px 10px;
  &:hover {
    background-color: white;
    border-radius: 10px;
  }
}

.widget-btn {
  background-color: transparent !important;
  border: 0px;
  width: 190px;
  min-height: 77px;
  color: black !important;

  &:active,
  &:hover,
  &:focus {
    background-color: white !important;
    border-radius: 10px;
    box-shadow: none !important;
  }
}

.t-view-subscrition {
  margin: 0;
  font-size: 11px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(17, 17, 17, 0.5);
}

.tooltip {
  z-index: 11111111;
}

.btn-light-gray {
  background-color: rgba(17, 17, 17, 0.1) !important;
  color: black !important;
  &:active,
  &:hover,
  &:focus {
    background-color: rgba(17, 17, 17, 0.2) !important;
  }
}

.custom-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    min-width: 270px;
  }
  .dropdown-divider {
    max-width: 90%;
    margin: auto;
  }

  .no-bg {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #82868b !important;
  }

  .dropdown-item {
    &:active,
    &:hover,
    &:focus {
      color: black !important;
    }
    .secondary-badge {
      color: #8d38ff;
      background: rgba(141, 56, 255, 0.1) !important;
      font-size: 0.6rem !important;
      border-radius: 25px;
    }
  }
}
.dlfHg div:first-child {
    overflow: visible !important;
  }
.user-custom-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    min-width: 270px;
  }
  .dropdown-divider {
    max-width: 90%;
    margin: auto;
  }

  .no-bg {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #82868b !important;
  }

  .dropdown-item {
    &:active,
    &:hover,
    &:focus {
      color: black !important;
    }
    .secondary-badge {
      color: #8d38ff;
      background: rgba(141, 56, 255, 0.1) !important;
      font-size: 0.6rem !important;
      border-radius: 25px;
    }
  }
}

.form-switch {
  min-height: 23px;
}
