.custom-builder-dialog {
    position: absolute;
    width: 100%;
    z-index: 111;
    margin-top: -150px;

    .switch-input {
        right: 0;
    }
}

.custom-builder-dialog-image{
    position: absolute;
    z-index: 111;
    left: 27px;
    top: 0;

}

.w-button{
    border: none !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
}

.dialog-custom-buider-backdrop {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    -webkit-backdrop-filter: grayscale(100%);
    backdrop-filter: grayscale(100%);
    pointer-events: all;
}

.quantity-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    
    input {
        max-width: 100px;
        &:focus-visible, &:focus {
            border: 0px;
            outline: 0px;
        }
    }
}

.line-through {
    text-decoration: line-through;
}